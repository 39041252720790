require('@rails/ujs').start()
require('@rails/activestorage').start()
require('channels')

// images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// javascripts
import 'sortablejs'
import 'bootstrap'
import 'bootstrap4-tagsinput/tagsinput'
import 'toastr'
import 'vue'
import 'vuedraggable'
import 'autosize'
import 'slick-carousel/slick/slick'
import 'payment'
import '../javascripts/pages/main'
import '../javascripts/common/main'

// stylesheets
import '../stylesheets/application'