$(() => {
  if (!$('#home_index').length) {
    return
  }

  // headerの高さをmainに付与する
  let setMainPaddingTop = () => {
    let height = $('header').innerHeight()
    // skeltonはpaddingなし
    if ($('.navbar-skelton').length > 0) {
      height = 0
    }
    $('main').css({ 'padding-top': height})
  }
  // 背景をスマホ版と切り替える
  let setLpTopBackground = () => {
    let jumbotron = $('.js-home-firstview-bg')
    let bgUrl = jumbotron.data('bg')
    let bgSpUrl = jumbotron.data('bg-sp')
    if (!bgUrl && !bgSpUrl) {
      return
    }
    if (window.innerWidth <= 540) {
      if (!!bgSpUrl) {
        jumbotron.css('background-image', "url(" + bgSpUrl + ")")
      } else {
        jumbotron.css('background-image', "url(" + bgUrl + ")")
      }
    } else {
      if (!!bgUrl) {
        jumbotron.css('background-image', "url(" + bgUrl + ")")
      } else {
        jumbotron.css('background-image', "none")
      }
    }
  }
  // 背景をスマホ版と切り替える
  let setLpTopMask = () => {
    let mask = $('.mc-home-lp_firstview_mask')
    let bgUrl = mask.data('bg')
    let bgSpUrl = mask.data('bg-sp')
    if (!bgUrl && !bgSpUrl) {
      return
    }
    if (window.innerWidth <= 540) {
      if (!!bgSpUrl) {
        mask.css('background-image', "url(" + bgSpUrl + ")")
      } else {
        mask.css('background-image', "url(" + bgUrl + ")")
      }
    } else {
      if (!!bgUrl) {
        mask.css('background-image', "url(" + bgUrl + ")")
      } else {
        mask.css('background-image', "none")
      }
    }
  }
  setMainPaddingTop()
  setLpTopBackground()
  setLpTopMask()
  $(window).resize(() => {
    setMainPaddingTop()
    setLpTopBackground()
    setLpTopMask()
  })

  // LP
  let switchSkelton = () => {
    let hasSkeltonOption = $('.mc-header').data('skelton')
    if (hasSkeltonOption == undefined) {
      return
    }

    // ヘッダーを透過する
    let scrollTop = $(window).scrollTop()
    if (scrollTop < 30) {
      $('.mc-header').addClass('navbar-skelton')
    } else {
      $('.mc-header').removeClass('navbar-skelton')
    }

    setMainPaddingTop()
  }
  // イベント
  $(window).scroll(() => {
    switchSkelton()
  })
  $('#about-link').on('click', () => {
    $("html,body").animate({scrollTop: $('#about-section').position().top }, 300)
  } )
  $('#feature-link').on('click', () => {
    $("html,body").animate({scrollTop: $('#feature-section').position().top }, 300)
  } )
  $('#faq-link').on('click', () => {
    $("html,body").animate({scrollTop: $('#faq-section').position().top }, 300)
  } )
  // 初期
  switchSkelton()

  // FAQ
  $('.mc-faq-toggle-link').on('click', (e) => {
    const self = $(e.currentTarget)
    const icon = self.closest('.mc-faq').find('i.mc-faq-toggle-icon')
    const targetId = self.attr('aria-controls')
    const answer = $(`#${targetId}`)
    // アイコンのトグル
    if (answer.hasClass('show')) {
      // 表示 -> 非表示
      icon.removeClass('fa-minus').addClass('fa-plus')
    } else {
      // 非表示 -> 表示
      icon.removeClass('fa-plus').addClass('fa-minus')
    }
  })

  // Portal
  $('.js-slicker').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    dotClass: 'mc-before-primary',
    centerMode: true,
    infinite: !$('.js-slicker').hasClass('pc-no-slider'),
    speed: 300,
    slidesToShow: 1,
    variableWidth: true,
    arrows: false,
  })
})
